
////////////////////////////////////////////////////////////////////////
// bootstrap5のwebpackコンパイルに役立つ参考URL
// https://www.webdesignleaves.com/pr/plugins/bootstrap5_webpack.html
// https://ics.media/entry/12140/
// https://www.webdesignleaves.com/pr/css/sass_webpack.html

////////////////////////////////////////////////////////////////////////
// BootstrapのJavaScript側の機能を読み込む
import * as bootstrap from 'bootstrap';

//ページ上のすべてのツールチップを初期化
const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  //new bootstrap.Tooltip() でインスタンスを生成
  return new bootstrap.Tooltip(tooltipTriggerEl)
});

////////////////////////////////////////////////////////////////////////
// // 必要なモジュールのみインポートする場合
// import { Tooltip, Carousel} from 'bootstrap';
// //または以下のように bootstrap/js/dist からインポートすることもできます
// import  Tooltip from 'bootstrap/js/dist/tooltip';
// import  Carousel from 'bootstrap/js/dist/carousel';

// //ページ上のすべてのツールチップを初期化
// const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
// const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//   //new Tooltip() でインスタンスを生成
//   return new Tooltip(tooltipTriggerEl)
// });

// スタイルシートを読み込む
import "./bootstrap.scss";